/* eslint-disable jsx-control-statements/jsx-for-require-each */
import { useState } from 'react'
import { Duration } from 'luxon'

import { useStyngDeactivate } from 'common/hook/useStyngDeactivate'
import { useStyngActivate } from 'common/hook/useStyngActivate'

import Text from 'locale/strings'

import Button from 'ui/Button/Button'
import Spinner from 'ui/Spinner/Spinner'
import Table from 'ui/Table/Table'
import Pagination from 'ui/Pagination/Pagination'
import JsonDataModalWithPlatform from 'ui/JsonDataModalWithPlatform/JsonDataModalWithPlatform'

import { CreateRFStyngRoute } from '../../index'
import { RoyaltyFreeStyng } from 'common/api/styng/styng'
import useStores from 'common/hook/useStore'
import { truncateText } from 'common/utils'
import { INITIAL_PAGE, FileType } from 'common/constants'

import styles from './StyngsRoyaltyFreeTable.module.scss'

interface StyngsRoyaltyFreeProps {
  styngs: RoyaltyFreeStyng[]
  pagesCount: number
  refetch: () => void
}

const StyngsRoyaltyFreeTable = ({ styngs, pagesCount, refetch }: StyngsRoyaltyFreeProps) => {
  const { styngsStore, navigationStore } = useStores()

  const [selectedStyng, setSelectedStyng] = useState<RoyaltyFreeStyng | null>(null)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  styngsStore.changePage(INITIAL_PAGE)

  // Pagination logic
  const handleChangePage = (value: number) => {
    styngsStore.changePage(value)
  }

  // Table data
  const columns = [
    'Image',
    'Id',
    'Track name',
    'Artists',
    'Styng name',
    'Duration',
    'Published',
    'External Metadata',
    'Actions',
  ]

  const dataTable = styngs.map((styng: RoyaltyFreeStyng) => [
    styng.imageUrl,
    styng.id,
    styng.trackName,
    styng.artists,
    truncateText(styng.name),
    Duration.fromISO(styng.duration).toFormat('mm:ss'),
    styng.isActive.toString(),
    Object.keys(styng.metadata).length === 0 ? 'NULL' : '',
  ])

  const onActivateRoyaltyFreeMutation = useStyngActivate((_: any) => {
    refetch()
  })

  const onDeactivateRoyaltyFreeMutation = useStyngDeactivate((_: any) => {
    refetch()
  })

  const handleActivate = (id: string, active: string) => {
    if (active === 'false') {
      onActivateRoyaltyFreeMutation.mutate({ styngId: id })
    } else {
      onDeactivateRoyaltyFreeMutation.mutate({ styngId: id })
    }
  }

  const handleOpenAdJsonModal = (id: string) => {
    const styng = styngs.filter((styng: RoyaltyFreeStyng) => styng.id === id)

    setSelectedStyng(styng[0])
    setJsonDataModal(true)
  }

  const handleCloseAdJsonModal = () => {
    setSelectedStyng(null)
    setJsonDataModal(false)
  }

  const handleCreateRFStyng = () => {
    navigationStore.goToPage(CreateRFStyngRoute.path)
  }

  return (
    <div className={styles.container}>
      {(onActivateRoyaltyFreeMutation.isLoading || onDeactivateRoyaltyFreeMutation.isLoading) && <Spinner />}

      <div className={styles.header}>
        <Button data-test="create-button" onClick={() => handleCreateRFStyng()}>
          {Text.navigation.createRFStyng}
        </Button>
      </div>

      <Table
        jsonData
        hideEdit
        hideDelete
        columns={columns}
        data={dataTable}
        hiddenColumns={['Id', 'External Metadata']}
        publishedToggle={true}
        nftIcon={true}
        handleActivate={handleActivate}
        onJsonDataClick={handleOpenAdJsonModal}
      />

      <If condition={pagesCount > 1}>
        <div className={styles.paginationContainer}>
          <div className={styles.pagination}>
            <Pagination page={styngsStore.page} count={pagesCount} onChange={handleChangePage} />
          </div>
        </div>
      </If>

      {jsonDataModal && (
        <JsonDataModalWithPlatform
          dataTypeId={selectedStyng?.id}
          data={selectedStyng?.metadata}
          dataType={FileType.STYNG}
          open={jsonDataModal}
          refetch={refetch}
          handleClose={handleCloseAdJsonModal}
        />
      )}
    </div>
  )
}

export default StyngsRoyaltyFreeTable
